// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// loaded by <%= javascript_pack_tag 'application'


import Turbolinks from 'turbolinks'
import 'jquery'
import 'bootstrap'

import { emailCaptureInit } from '../../javascript/application/email-capture'
import { audioInit } from '../../javascript/application/devo-audio'
import { threddedInit } from '../../javascript/application/thredded-init'
import { scrollProgress } from '../../javascript/application/scroll-init'
import { shareInit } from '../../javascript/application/share-init'
import { contactFormInit } from '../../javascript/application/contact-form'
import { parallax } from '../../javascript/application/parallax'
import { turbolinksScrollPersistenceInit } from '../../javascript/application/turbolinks-scroll-persistence'
import { flashNotice } from '../../javascript/application/flash-notice'
import { studyNotesInit } from '../../javascript/application/study-notes'
import { frontMatterInit } from '../../javascript/application/front-matter'

// inject styles with vite
import '../entrypoints/application.scss'
import { pdfViewerInit } from '../../javascript/application/pdf-viewer'

declare global {
  interface Window {
    refTagger?: { tag: (node: HTMLElement) => void }
  }
}

Turbolinks.start()


$(document).on('turbolinks:before-visit', () => {
  $(document.body).addClass('turbolinks-show-progress')
})
turbolinksScrollPersistenceInit()
threddedInit()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
$(document).on('render_async_load', function(_event: any) {
  if(window.refTagger?.tag) {
    window.refTagger.tag(document.body)
  }
})

$(document.body).on('click', '[data-href]', function() {
  const href = $(this).data('href')
  if (href && href.length > 0) {
    window.location = href
  }
})

// Stuff that should be initialized anytime Turbolinks loads a page
function init() {
  emailCaptureInit()
  audioInit()
  shareInit()
  contactFormInit()
  flashNotice()

  if(document.getElementsByClassName('contains-study-notes').length > 0) {
    studyNotesInit()
  }

  if(document.getElementsByClassName('front-matter-canvas').length > 0) {
    frontMatterInit()
  }

  if(document.getElementById('navbar')?.classList.contains('fixed-top')) {
    scrollProgress()
  }

  if(document.getElementById('parallax')) {
    parallax()
  }
  
  if (document.getElementById('pdf-viewer')) {
    pdfViewerInit().then(() => {
      console.log('PDF viewer initialized')
    }).catch((error) => {
      console.error('Error initializing PDF viewer:', error)
    })
  }

  if (window.refTagger?.tag) {
    window.refTagger.tag(document.body)
  }
}

$(document).on('turbolinks:load', init)
