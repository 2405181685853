import { getDocument } from 'pdfjs-dist'

export async function pdfViewerInit() {
  const pdfViewer = document.getElementById('pdf-viewer')
  if (!pdfViewer) return

  const pageNumbers = pdfViewer.getAttribute('data-page-numbers')?.split(',') || ['1']
  const pdfUrl = pdfViewer.getAttribute('data-pdf-url')!

  try {
    // Initialize PDF.js worker
    const pdfjsLib = await import('pdfjs-dist')
    pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdfjs-4.10.38-dist/build/pdf.worker.js'

    // Load the PDF document
    const loadingTask = getDocument(pdfUrl)
    const pdf = await loadingTask.promise

    // Get all the pages
    const pages = await Promise.all(pageNumbers.map(pageNumber => pdf.getPage(parseInt(pageNumber, 10))))
    
    for (const page of pages) {
      // Calculate scale to fit the container width
      const viewport = page.getViewport({ scale: 1.0 })
      const container = pdfViewer.parentElement as HTMLElement
      const scale = container.clientWidth / viewport.width
      
      // Double the scale for higher resolution rendering
      const renderScale = scale * 2
      const scaledViewport = page.getViewport({ scale: renderScale })

      // Prepare canvas for rendering
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      
      if (!context) {
        throw new Error('Could not get canvas context')
      }

      // Set canvas dimensions to the higher resolution size
      canvas.height = scaledViewport.height
      canvas.width = scaledViewport.width
      // Set display size to the original scale through CSS
      canvas.style.width = `${viewport.width * scale}px`
      canvas.style.height = `${viewport.height * scale}px`

      // Add canvas to the viewer element inside a div
      const pageDiv = document.createElement('div')
      pageDiv.className = 'pdf-page col-12'
      pageDiv.appendChild(canvas)
      pdfViewer.appendChild(pageDiv)

      // Render the PDF page
      const renderContext = {
        canvasContext: context,
        viewport: scaledViewport,
      }

      await page.render(renderContext).promise
    }

  } catch (error) {
    console.error('Error loading PDF:', error)
    pdfViewer.innerHTML = '<p>Error loading PDF. Please try again later.</p>'
  }
}
